body {
  margin: 0;
  font-family: 'Regular';
  display: flex;
  flex: 1;
}

html {
  display: flex;
  flex: 1;
  height: 100%;
}

#root {
  display: flex;
  flex: 1;
}

span {
  white-space: pre-line;
}

a {
  text-decoration: none;
  color: inherit;
  margin: 0;
  padding: 0;
}

span.blogDetailText ul {
  margin: 0;
  padding: 0;
  padding-left: 2em;
}

span.blogDetailText li {
  margin: 0;
  padding: 0;
}

span.blogDetailText p {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.ant-col .ant-form-item-label {
  padding-bottom: 4px;
  padding-left: 4px;
}

.ant-form-item-explain-error {
  margin-left: 4px;
}

.ant-checkbox-wrapper > .ant-checkbox > .ant-checkbox-inner {
  width: 24px;
  height: 24px;
}

.ant-checkbox-wrapper > .ant-checkbox > .ant-checkbox-inner::after {
  width: 8px;
  height: 14px;
}

.customHtml > p > span {
  white-space: normal;
}

@font-face {
  font-family: 'Black';
  src: local('Gilroy'), url('./fonts/Gilroy-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Bold';
  src: local('Gilroy'), url('./fonts/Gilroy-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'ExtraBold';
  src: local('Gilroy'), url('./fonts/Gilroy-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Heavy';
  src: local('Gilroy'), url('./fonts/Gilroy-Heavy.ttf') format('truetype');
}

@font-face {
  font-family: 'Light';
  src: local('Gilroy'), url('./fonts/Gilroy-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Medium';
  src: local('Gilroy'), url('./fonts/Gilroy-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Regular';
  src: local('Gilroy'), url('./fonts/Gilroy-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'SemiBold';
  src: local('Gilroy'), url('./fonts/Gilroy-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Thin';
  src: local('Gilroy'), url('./fonts/Gilroy-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'UltraLight';
  src: local('Gilroy'), url('./fonts/Gilroy-UltraLight.ttf') format('truetype');
}
