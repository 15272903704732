button:hover {
  opacity: 0.8;
}

button:active {
  opacity: 0.65;
}

button:disabled {
  opacity: 0.5;
}
