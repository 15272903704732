.lightboxContainer {
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #00000077;
  z-index: 10;
  align-items: center;
  justify-content: center;
}
.hide {
  display: none;
}

.closeButton {
  cursor: pointer;
  text-align: right;
  font-weight: 700;
  font-size: 1.5rem;
}
